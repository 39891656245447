import React from 'react';

// In Footer/index.js
const Footer = () => {
  const footerLinks = {
    Product: [
      { name: 'Features', href: '#benefits' },
      { name: 'How it Works', href: '#how-it-works' },
      { name: 'Pricing', href: '/subscribe' },
      { name: 'Case Studies', href: '#case-studies' }
    ],
    Company: [
      // { name: 'About Us', href: '/about' },
      // { name: 'Contact', href: '/contact' },
      { name: 'Privacy Policy', href: '/privacy' }, // Updated link
      { name: 'Terms of Service', href: '/terms' }  // Updated link
      // { name: 'Privacy Policy', href: '/privacy' },
      // { name: 'Terms of Service', href: '/terms' }
    ],
    Support: [
      { name: 'FAQ', href: '#faq' },
      // { name: 'Documentation', href: '/docs' },
      // { name: 'API Reference', href: '/api' }
    ]
  };

  return (
    <footer className="bg-gray-900 border-t border-gray-800">
      <div className="container mx-auto px-4 py-12">
        <div className="grid md:grid-cols-4 gap-8">
          {/* Logo and Description */}
          <div className="col-span-1">
            <h3 className="text-xl font-bold text-blue-400 mb-4">Finjy</h3>
            <p className="text-gray-400">
              Your intelligent financial auditor powered by multiple AI models to help you understand and manage your finances better.
            </p>
          </div>

          {/* Dynamic Links Sections */}
          {Object.entries(footerLinks).map(([category, links]) => (
            <div key={category} className="space-y-4">
              <h4 className="text-sm font-semibold text-gray-300">{category}</h4>
              <ul className="space-y-2">
                {links.map(link => (
                  <li key={link.name}>
                    <a 
                      href={link.href} 
                      className="text-gray-400 hover:text-blue-400 transition-colors"
                    >
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Finjy. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;