// src/components/Chat/ChatHistory.jsx
import React from 'react';
import { format } from 'date-fns';

const ChatHistory = ({ isOpen, onClose, chats, onSelectChat, isLoading }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-y-0 left-0 w-80 bg-white shadow-xl transform transition-transform duration-300 ease-in-out z-30">
      <div className="h-full flex flex-col">
        {/* Header with close button */}
        <div className="px-4 py-3 border-b border-gray-200 flex items-center justify-between">
          <h2 className="text-lg font-medium text-gray-900">Chat History</h2>
          <button
            onClick={onClose}
            className="p-1 rounded-md hover:bg-gray-100 text-gray-400 hover:text-gray-500"
          >
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        {/* ... header ... */}
        <div className="flex-1 overflow-y-auto">
          {isLoading ? (
            <div className="text-center py-8 text-gray-500">
              Loading chats...
            </div>
          ) : chats?.length > 0 ? (
            chats.map((chat) => (
              <button
                key={chat.id}
                onClick={() => onSelectChat(chat)}
                className="w-full px-4 py-3 hover:bg-gray-50 flex items-start justify-between text-left border-b border-gray-100"
              >
                <div>
                  <h3 className="text-sm font-medium text-gray-900 truncate max-w-[180px]">
                    {chat.title || 'Untitled Chat'}
                  </h3>
                  <p className="text-xs text-gray-500 mt-1">
                    {format(new Date(chat.created_at), 'MMM d, yyyy h:mm a')}
                  </p>
                  <p className="text-xs text-gray-400 mt-1">
                    {new Set(chat.messages?.filter(msg => msg && msg.content).map(msg => msg.id)).size/2 || 0} messages
                  </p>
                </div>
                <span className="text-xs text-gray-400 ml-2">
                  {chat.model_provider}  {/* This will show the correct provider */}
                </span>
              </button>
            ))
          ) : (
            <div className="text-center py-8 text-gray-500">
              No previous chats found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatHistory;