import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { createSubscription, fetchSubscriptionStatus } from '../../store/thunks/subscriptionThunks';
import { PLAN_FEATURES } from '../../config/planFeatures';

const CheckoutForm = ({ planId, interval, onCancel }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [processingPayment, setProcessingPayment] = useState(false);
  const isTrialEligible = PLAN_FEATURES[planId]?.trial?.eligible && interval === 'monthly';
  const trialDays = PLAN_FEATURES[planId]?.trial?.days || 0;

  const handlePaymentConfirmation = async (subscriptionResult) => {
    setProcessingPayment(true);
    setMessage('Confirming payment...');

    try {
      if (!subscriptionResult.client_secret) {
        throw new Error('No payment confirmation required');
      }

      const { error: confirmError } = await stripe.confirmCardPayment(
        subscriptionResult.client_secret
      );

      if (confirmError) {
        throw new Error(confirmError.message);
      }

      // Get final subscription status
      const finalStatus = await dispatch(fetchSubscriptionStatus());
      
      if (!finalStatus || finalStatus.status !== 'active') {
        throw new Error('Subscription activation failed');
      }

      setMessage('Payment confirmed! Redirecting...');
      return finalStatus;
    } catch (error) {
      throw new Error(`Payment confirmation failed: ${error.message}`);
    } finally {
      setProcessingPayment(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      setError('Payment system not initialized. Please try again.');
      return;
    }

    setLoading(true);
    setError(null);
    setMessage('Processing your subscription...');

    try {
      // Create payment method
      const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (stripeError) {
        throw new Error(stripeError.message);
      }

      if (!paymentMethod) {
        throw new Error('Failed to process payment method. Please try again.');
      }

      // Create subscription
      const subscriptionResult = await dispatch(createSubscription(
        planId, 
        paymentMethod.id, 
        interval
      ));

      // Handle payment confirmation if needed
      await handlePaymentConfirmation(subscriptionResult);

      // Successful subscription
      setMessage('Subscription created successfully! Redirecting...');
      const destination = location.state?.from?.pathname || '/dashboard';
      setTimeout(() => {
        navigate(destination);
      }, 2000);

    } catch (err) {
      setError(
        err.response?.data?.detail || 
        err.message || 
        'An error occurred while processing your subscription'
      );
      setMessage('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-4">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <CardElement
            className="p-3 border rounded-md"
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
              // hidePostalCode: true,
            }}
          />
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md text-red-600 text-sm">
            {error}
          </div>
        )}

        {message && (
          <div className="mb-4 p-3 bg-green-50 border border-green-200 rounded-md text-green-600 text-sm">
            {message}
          </div>
        )}

        {isTrialEligible ? (
          <div className="text-sm text-gray-600 mt-2">
            Start your {trialDays}-day free trial today. After the trial, you'll be charged ${PLAN_FEATURES[planId].monthly}/month unless cancelled.
          </div>
        ) : (
          <div className="text-sm text-gray-600 mt-2">
            You'll be charged immediately for this subscription.
          </div>
        )}

        <div className="mt-4 space-x-3">
          <button
            type="submit"
            disabled={!stripe || loading || processingPayment}
            className={`
              inline-flex justify-center px-4 py-2 text-sm font-medium text-white 
              bg-blue-600 border border-transparent rounded-md 
              hover:bg-blue-700 focus:outline-none focus:ring-2 
              focus:ring-offset-2 focus:ring-blue-500
              ${(loading || processingPayment) ? 'opacity-50 cursor-not-allowed' : ''}
            `}
          >
            {loading || processingPayment ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {processingPayment ? 'Confirming Payment...' : 'Processing...'}
              </span>
            ) : (
              'Subscribe'
            )}
          </button>

          <button
            type="button"
            onClick={onCancel}
            disabled={loading || processingPayment}
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;