// src/App.js
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { store, persistor } from './store';
import Auth0ProviderWithNavigate from './providers/Auth0Provider';
import LoadingScreen from './components/common/LoadingScreen';
import AppRoutes from './routes';
import { Toaster } from 'react-hot-toast';
import { AnalyticsProvider } from './components/analytics/GoogleAnalytics';
import { FeedbackProvider } from './providers/FeedbackProvider'  // Add this

function App() {
  return (
    <Provider store={store}>
      <Toaster position="top-right" />
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <HelmetProvider>
          <BrowserRouter>
            <Auth0ProviderWithNavigate>
              <AnalyticsProvider>
                <FeedbackProvider>  {/* Replace GleapProvider with FeedbackProvider */}
                  <AppRoutes />
                </FeedbackProvider>
              </AnalyticsProvider>
            </Auth0ProviderWithNavigate>
          </BrowserRouter>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;