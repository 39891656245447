// store/thunks/feedbackThunks.js
import api from '../../services/api';
import {
  setFeedbackLoading,
  setFeedbackError,
  addFeedbackSubmission
} from '../slices/feedbackSlice';
import { addNotification } from '../slices/uiSlice';
import { toast } from 'react-hot-toast';

export const submitFeedback = (feedbackData) => async (dispatch) => {
  try {
    dispatch(setFeedbackLoading(true));
    const response = await api.post('/api/v1/feedback/issue', feedbackData);
    dispatch(addFeedbackSubmission(response.data));
    
    toast.success('Thank you for your feedback!');
    
    dispatch(addNotification({
      type: 'success',
      message: 'Feedback submitted successfully'
    }));
    
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.detail || 'Failed to submit feedback';
    dispatch(setFeedbackError(errorMessage));
    
    toast.error(errorMessage);
    
    dispatch(addNotification({
      type: 'error',
      message: errorMessage
    }));
    
    throw error;
  } finally {
    dispatch(setFeedbackLoading(false));
  }
};