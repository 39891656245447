// src/components/feedback/FeedbackButton.js
import React from 'react';
import { MessageSquarePlus } from 'lucide-react';

export const FeedbackButton = ({ onClick }) => {
  return (
    <button 
      onClick={onClick}
      className="fixed bottom-4 right-4 bg-blue-600 text-white p-2 rounded-full shadow-lg hover:bg-blue-700 transition-colors"
      aria-label="Give Feedback"
    >
      <MessageSquarePlus size={24} />
    </button>
  );
};