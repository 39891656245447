// src/store/slices/chatSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: [],
  loading: false,
  error: null,
  usage: {
    dailyCount: 0,
    monthlyCount: 0,
    lastReset: new Date().toISOString()
  }
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatLoading: (state, action) => {
      state.loading = action.payload;
    },
    setChatError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    addMessage: (state, action) => {
      state.messages.push(action.payload);
      // Increment usage counters
      state.usage.dailyCount++;
      state.usage.monthlyCount++;
    },
    clearMessages: (state) => {
      state.messages = [];
    },
    resetDailyCount: (state) => {
      state.usage.dailyCount = 0;
      state.usage.lastReset = new Date().toISOString();
    },
    resetMonthlyCount: (state) => {
      state.usage.monthlyCount = 0;
    }
  }
});

export const {
  setChatLoading,
  setChatError,
  addMessage,
  clearMessages,
  resetDailyCount,
  resetMonthlyCount
} = chatSlice.actions;

export const selectChatMessages = (state) => state.chat.messages;
export const selectChatLoading = (state) => state.chat.loading;
export const selectChatError = (state) => state.chat.error;
export const selectChatUsage = (state) => state.chat.usage;

export default chatSlice.reducer;