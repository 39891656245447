// src/config/planFeatures.js
export const PLAN_FEATURES = {
  free: {
    id: 'free',
    name: 'Free',
    description: 'Get started for free',
    monthly: 0,
    yearly: 0,
    features: {
      projects: 4,
      bank_connections: 1,
      chat_history_days: 3,
      storage: '1GB',
      analytics: 'basic',
      support: 'email',
      allowed_models: ['gpt-3.5-turbo', 'gemini-pro'],
      routes: ['/dashboard', '/settings'],
      featureList: [
        'Up to 4 projects',
        'Basic analytics',
        'Email support',
        '1GB storage',
        'Connect 1 bank account',
        'Access to GPT-3.5'
      ]
    },
    limits: {
      max_chats: 10,
      max_messages_per_chat: 5,
      monthly_queries: 50,
      requests_per_day: 10
    }
  },
  basic: {
    id: 'basic',
    name: 'Basic',
    description: 'Perfect for individual users',
    monthly: 9.99,
    yearly: 99.99,
    features: {
      projects: 10,
      bank_connections: 3,
      chat_history_days: 7,
      storage: '5GB',
      analytics: 'basic',
      support: 'email',
      allowed_models: ['gpt-3.5-turbo', 'gemini-pro'],
      routes: ['/dashboard', '/settings'],
      featureList: [
        'Up to 10 projects',
        'Basic analytics',
        'Email support',
        '5GB storage',
        'Connect 3 bank account',
        'Access to GPT-3.5'
      ]
    },
    limits: {
      max_chats: 30,
      max_messages_per_chat: 10,
      monthly_queries: 100,
      requests_per_day: 20
    }
  },
  pro: {
    id: 'pro',
    name: 'Pro',
    description: 'Ideal for professionals',
    monthly: 19.99,
    yearly: 199.99,
    features: {
      projects: -1, // unlimited
      bank_connections: 3,
      chat_history_days: 30,
      storage: '50GB',
      analytics: 'advanced',
      support: 'priority',
      allowed_models: ['gpt-3.5-turbo', 'grok-beta', 'gemini-pro'],
      routes: ['/dashboard', '/settings', '/analytics'],
      featureList: [
        'Unlimited projects',
        'Advanced analytics',
        'Priority support',
        '50GB storage',
        'Connect up to 3 bank accounts',
        'Access to GPT-3.5 and Grok'
      ]
    },
    limits: {
      max_chats: 50,
      max_messages_per_chat: 25,
      monthly_queries: 500,
      requests_per_day: 50
    }
  },
  enterprise: {
    id: 'enterprise',
    name: 'Enterprise',
    description: 'For large organizations',
    monthly: 49.99,
    yearly: 499.99,
    features: {
      projects: -1,
      bank_connections: -1,
      chat_history_days: -1,
      storage: 'unlimited',
      analytics: 'enterprise',
      support: 'dedicated',
      allowed_models: ['gpt-3.5-turbo', 'grok-beta', 'claude-3-sonnet-20240229', 'gemini-pro'],
      routes: ['/dashboard', '/settings', '/analytics', '/admin'],
      featureList: [
        'Everything in Pro',
        'Dedicated support',
        'Unlimited storage',
        'Connect unlimited bank accounts',
        'Access to all AI models'
      ]
    },
    limits: {
      max_chats: -1, // unlimited
      max_messages_per_chat: -1, // unlimited
      monthly_queries: -1, // unlimited
      requests_per_day: -1 // unlimited
    }
  }
};

export const MODEL_REQUIREMENTS = {
  'gpt-3.5-turbo': ['basic', 'pro', 'enterprise'],
  'grok-beta': ['pro', 'enterprise'],
  'claude-3-sonnet-20240229': ['enterprise'],
  'gemini-pro': ['basic', 'pro', 'enterprise']  // Add this line
};

export const getRequiredPlanForModel = (modelId) => {
  return MODEL_REQUIREMENTS[modelId]?.[0] || 'enterprise';
};