import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../../components/Layouts/DashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadIcon } from '@heroicons/react/outline';
import { selectSubscription } from '../../store/slices/subscriptionSlice';
import { fetchSubscriptionStatus, cancelSubscription, updatePaymentMethod, changePlan } from '../../store/thunks/subscriptionThunks';
import { fetchPaymentMethod } from '../../store/thunks/paymentMethodThunks';
import { selectPaymentMethod } from '../../store/slices/paymentMethodSlice';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentMethodModal from '../../components/subscription/PaymentMethodModal';
import ChangePlanModal from '../../components/subscription/ChangePlanModal';
import CancelSubscriptionModal from '../../components/subscription/CancelSubscriptionModal';
import ReactivationBanner from '../../components/subscription/ReactivationBanner';
import { reactivateSubscription } from '../../store/thunks/subscriptionThunks';
import { useSubscriptionToasts } from '../../hooks/useSubscriptionToasts';
import BillingHistory from '../../components/subscription/BillingHistory';
import { PLAN_FEATURES } from '../../config/planFeatures';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscription = useSelector(selectSubscription);
  const paymentMethod = useSelector(selectPaymentMethod);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const toasts = useSubscriptionToasts();

  // Ensure proper plan assignment
  const currentPlan = PLAN_FEATURES[subscription?.currentPlan] ? subscription?.currentPlan : 'free';
  const isFree = currentPlan === 'free';
  const planInfo = PLAN_FEATURES[currentPlan];

  useEffect(() => {
    dispatch(fetchSubscriptionStatus());
    dispatch(fetchPaymentMethod());
  }, [dispatch]);

  const handleReactivate = async () => {
    const loadingToast = toasts.loading.start('Reactivating subscription...');
    try {
      await dispatch(reactivateSubscription());
      toasts.loading.dismiss(loadingToast);
      toasts.success.subscriptionReactivated();
    } catch (error) {
      toasts.loading.dismiss(loadingToast);
      toasts.error.subscriptionReactivate(error);
    }
  };

  const handleChangePlan = async (newPlanId) => {
    if (newPlanId === 'free') {
      // Handle downgrade to free tier if needed
      return;
    }

    try {
      const result = await dispatch(changePlan(newPlanId, toasts));
      
      if (result.requiresPaymentMethod) {
        setIsPaymentModalOpen(true);
        return;
      }
      
      setIsPlanModalOpen(false);
    } catch (error) {
      console.error('Error changing plan:', error);
    }
  };

  const handleUpdatePayment = async (paymentMethodId) => {
    try {
      await dispatch(updatePaymentMethod(paymentMethodId, toasts));
      await dispatch(fetchSubscriptionStatus());
      await dispatch(fetchPaymentMethod());
      setIsPaymentModalOpen(false);
    } catch (error) {
      console.error('Error updating payment method:', error);
    }
  };

  const handleCancelSubscription = async () => {
    if (isFree) return; // Prevent cancellation of free tier

    const loadingToast = toasts.loading.start('Canceling subscription...');
    try {
      await dispatch(cancelSubscription());
      setIsCancelModalOpen(false);
      toasts.loading.dismiss(loadingToast);
      const endDate = new Date(subscription.currentPeriodEnd).toLocaleDateString();
      toasts.success.subscriptionCanceled(endDate);
    } catch (error) {
      toasts.loading.dismiss(loadingToast);
      toasts.error.subscriptionCancel(error);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getPlanAmount = (planId) => {
    if (planId === 'free') return '0.00';
    const plan = PLAN_FEATURES[planId];
    return plan ? plan[subscription?.interval || 'monthly'].toString() : '0.00';
  };

  return (
    <DashboardLayout>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* Current Plan */}
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
          {!isFree && (
            <ReactivationBanner 
              subscription={subscription} 
              onReactivate={handleReactivate}
              paymentMethod={paymentMethod}
              onAddPayment={() => setIsPaymentModalOpen(true)}
            />
          )}

          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
              Current Subscription
            </h3>
            <div className="mt-5">
              <div className="rounded-md bg-gray-50 dark:bg-gray-700 px-6 py-5 sm:flex sm:items-start sm:justify-between">
                <div className="sm:flex sm:items-start">
                  <div>
                    <h4 className="text-lg font-medium text-gray-900 dark:text-white">
                      {planInfo?.name || 'Free'} Plan
                    </h4>
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                      ${getPlanAmount(currentPlan)}/{isFree ? 'month' : subscription?.interval}
                    </p>
                  </div>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6">
                  <span className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${
                    isFree || subscription?.status === 'active'
                      ? 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-200' 
                      : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-200'
                  }`}>
                    {isFree ? 'Active' : subscription?.status?.charAt(0).toUpperCase() + subscription?.status?.slice(1)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Billing Information */}
        {/* Billing Information */}
        <div className="mt-6 bg-white dark:bg-gray-800 shadow rounded-lg">
          {!paymentMethod?.card?.last4 && subscription.status === 'trialing' && (
            <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
              <div className="flex">
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">
                    Payment Method Required
                  </h3>
                  <p className="mt-2 text-sm text-yellow-700">
                    Action Required: Add a payment method to continue service
                  </p>
                  <button
                    onClick={() => setIsPaymentModalOpen(true)}
                    className="mt-2 text-sm font-medium text-yellow-800 hover:text-yellow-900"
                  >
                    Add Payment Method →
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* Rest stays the same */}
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
              Billing Information
            </h3>
            <div className="mt-5">
              <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    Next billing date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                    {formatDate(subscription.currentPeriodEnd)}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                    Payment method
                  </dt>
                  {/* Update this line in the Billing Information section */}
                  {/* <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                    {paymentMethod ? 'Payment method found' : 'No payment method on file'}
                  </dd> */}
                  <dd className="mt-1 text-sm text-gray-900 dark:text-white flex items-center">
                    {paymentMethod === true ? (
                      <span>Payment method found</span>
                    ) : (
                      <>
                        <span>No payment method on file</span>
                        <button
                          onClick={() => setIsPaymentModalOpen(true)}
                          className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 text-sm ml-2"
                        >
                          Add now
                        </button>
                      </>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>

        {/* Actions */}
        <div className="mt-5 space-y-4">
          <button
            type="button"
            onClick={() => setIsPlanModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-blue-600 dark:border-blue-500 rounded-md shadow-sm text-sm font-medium text-blue-600 dark:text-blue-400 bg-white dark:bg-gray-800 hover:bg-blue-50 dark:hover:bg-gray-700">
            {isFree ? 'Upgrade Plan' : 'Change Plan'}
          </button>
          
          {!isFree && (
            <>
              <button
                type="button"
                onClick={() => setIsPaymentModalOpen(true)}
                className="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700">
                {paymentMethod?.card ? 'Update Payment Method' : 'Add Payment Method'}
              </button>
              
              <button
                type="button"
                onClick={() => setIsCancelModalOpen(true)}
                className="inline-flex items-center px-4 py-2 border border-red-600 dark:border-red-500 rounded-md shadow-sm text-sm font-medium text-red-600 dark:text-red-400 bg-white dark:bg-gray-800 hover:bg-red-50 dark:hover:bg-gray-700">
                Cancel Subscription
              </button>
            </>
          )}
        </div>

        {/* Billing History */}
        <div className="mt-6">
          {isFree ? (
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                  Billing History
                </h3>
                <div className="mt-4 text-sm text-gray-500 dark:text-gray-400">
                  Billing history is not available on the free plan. Upgrade to a paid plan to access billing history.
                </div>
              </div>
            </div>
          ) : (
            <BillingHistory />
          )}
        </div>

        {/* Modals */}
        <Elements stripe={stripePromise}>
          <PaymentMethodModal
            isOpen={isPaymentModalOpen}
            onClose={() => setIsPaymentModalOpen(false)}
            onUpdate={handleUpdatePayment}
          />
        </Elements>

        <ChangePlanModal
          isOpen={isPlanModalOpen}
          onClose={() => setIsPlanModalOpen(false)}
          onChangePlan={handleChangePlan}
          currentPlan={currentPlan}
          interval={subscription?.interval}
          subscription={subscription}
        />

        <CancelSubscriptionModal
          isOpen={isCancelModalOpen}
          onClose={() => setIsCancelModalOpen(false)}
          onConfirm={handleCancelSubscription}
          subscription={subscription}
        />
      </div>
    </DashboardLayout>
  );
};

export default SubscriptionManagement;