import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Public Pages
import LandingPage from '../pages/LandingPage';
import SignIn from '../pages/Auth/SignIn';
import SignUp from '../pages/Auth/SignUp';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import Subscribe from '../pages/Subscribe';
import NotFound from '../pages/NotFound';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfService from '../pages/TermsOfService';

// Protected Pages
import Dashboard from '../pages/Dashboard';
import Analytics from '../pages/Analytics';
import Profile from '../pages/Profile';
import Settings from '../pages/Settings';
import SubscriptionManagement from '../pages/SubscriptionManagement';
import TestPermissions from '../components/TestPermissions';
import UpgradePage from '../pages/upgrade';
import ConnectBank from '../pages/ConnectBank';

// Components
import ProtectedRoute from '../components/routing/ProtectedRoute';
import SubscriptionGuard from '../components/guards/SubscriptionGuard';

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<LandingPage />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfService />} />
      
      {/* These routes should be accessible to authenticated users without subscription */}
      <Route
        path="/subscribe"
        element={
          <ProtectedRoute>
            <Subscribe />
          </ProtectedRoute>
        }
      />
      <Route
        path="/upgrade"
        element={
          <ProtectedRoute>
            <UpgradePage />
          </ProtectedRoute>
        }
      />
      <Route path="/test-permissions" element={<TestPermissions />} />

      {/* Core Product Routes - Available to Free Tier */}
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/connectBank"
        element={
          <ProtectedRoute>
            <ConnectBank />
          </ProtectedRoute>
        }
      />

      {/* Premium Features - Require Subscription */}
      <Route
        path="/analytics"
        element={
          <ProtectedRoute>
            <SubscriptionGuard requiredPlans={['basic', 'pro', 'enterprise']}>
              <Analytics />
            </SubscriptionGuard>
          </ProtectedRoute>
        }
      />

      {/* Basic Account Management - Available to All Users */}
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/subscription"
        element={
          <ProtectedRoute>
            <SubscriptionManagement />
          </ProtectedRoute>
        }
      />

      {/* 404 Route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;