import React from 'react';
import { Link } from 'react-router-dom';
import MainLayout from '../../../components/Layouts/MainLayout';

const AuthLayout = ({ children, title, subtitle }) => {
  return (
    <MainLayout withFooter={false}>
      <div className="min-h-screen bg-gray-900 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Link to="/">
            <h2 className="text-center text-3xl font-bold text-blue-400">
              Finjy
            </h2>
          </Link>
          <h2 className="mt-6 text-center text-3xl font-bold text-white">
            {title}
          </h2>
          {subtitle && (
            <p className="mt-2 text-center text-sm text-gray-400">
              {subtitle}
            </p>
          )}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-gray-800 py-8 px-4 shadow-lg border border-gray-700 sm:rounded-lg sm:px-10">
            {children}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default AuthLayout;