// store/thunks/paymentMethodThunks.js
import api from '../../services/api';
import { 
  setPaymentMethod, 
  setLoading, 
  setError 
} from '../slices/paymentMethodSlice';

// # In paymentMethodThunks.js, update the path to match the new endpoint:
export const fetchPaymentMethod = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get('/api/v1/payment-methods/method');  // Changed from /default
    dispatch(setPaymentMethod(response.data));
    return response.data;
  } catch (error) {
    // If 404, it means no payment method is set - this is a valid state
    if (error.response?.status === 404) {
      dispatch(setPaymentMethod(null));
      return null;
    }
    dispatch(setError(error.response?.data?.detail || 'Failed to fetch payment method'));
    return null;
  } finally {
    dispatch(setLoading(false));
  }
};

export const savePaymentMethod = (paymentMethodId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.post('/api/v1/payment-methods/default', {
      payment_method_id: paymentMethodId
    });
    dispatch(setPaymentMethod(response.data));
    return response.data;
  } catch (error) {
    dispatch(setError(error.response?.data?.detail || 'Failed to save payment method'));
    throw error;
  } finally {
    dispatch(setLoading(false));
  }
};