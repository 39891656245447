import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';

const getDaysUntilExpiration = (subscription) => {
  if (!subscription?.currentPeriodEnd) return 0;
  const periodEnd = new Date(subscription.currentPeriodEnd);
  const now = new Date();
  return Math.ceil((periodEnd - now) / (1000 * 60 * 60 * 24));
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const ReactivationBanner = ({ subscription, onReactivate, paymentMethod, onAddPayment }) => {
  if (!subscription || subscription.currentPlan === 'free') return null;

  const daysLeft = getDaysUntilExpiration(subscription);
  const isExpired = daysLeft <= 0;
  const noPaymentMethod = !paymentMethod?.card?.last4;
  const endDate = formatDate(subscription.currentPeriodEnd);

  // Don't show banner if subscription is active and not cancelled
  if (!subscription.cancelAtPeriodEnd && subscription.status === 'active') {
    return null;
  }

  // Determine banner color scheme based on status
  const getBannerColors = () => {
    if (isExpired || noPaymentMethod) {
      return {
        bg: 'bg-red-50',
        border: 'border-red-400',
        icon: 'text-red-400',
        text: 'text-red-700',
        button: 'text-red-700 bg-red-100 hover:bg-red-200 focus:ring-red-500'
      };
    }
    return {
      bg: 'bg-yellow-50',
      border: 'border-yellow-400',
      icon: 'text-yellow-400',
      text: 'text-yellow-700',
      button: 'text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:ring-yellow-500'
    };
  };

  const colors = getBannerColors();

  // Get appropriate message based on subscription state
  const getMessage = () => {
    if (subscription.cancelAtPeriodEnd) {
      return isExpired
        ? "Your paid subscription has ended. Your account has reverted to the free tier. Reactivate now to restore premium features."
        : `Your subscription will end on ${endDate}. After that, your account will revert to the free tier.`;
    }

    if (noPaymentMethod) {
      return "Add a payment method to activate premium features.";
    }

    return null;
  };

  const message = getMessage();
  if (!message) return null;

  const getActionButton = () => {
    if (noPaymentMethod) {
      return (
        <button
          type="button"
          onClick={onAddPayment}
          className={`inline-flex items-center px-4 py-2 border border-transparent 
                     text-sm font-medium rounded-md ${colors.button}
                     focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          Add Payment Method
        </button>
      );
    }

    if (subscription.cancelAtPeriodEnd) {
      return (
        <button
          type="button"
          onClick={onReactivate}
          className={`inline-flex items-center px-4 py-2 border border-transparent 
                     text-sm font-medium rounded-md ${colors.button}
                     focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          Reactivate Premium
        </button>
      );
    }

    return null;
  };

  const actionButton = getActionButton();

  return (
    <div className={`${colors.bg} border-l-4 ${colors.border} p-4 mb-6`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon 
            className={`h-5 w-5 ${colors.icon}`}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className={`text-sm ${colors.text}`}>
            {message}
          </p>
          {actionButton && (
            <div className="mt-4">
              {actionButton}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReactivationBanner;