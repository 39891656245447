import React from 'react';
import { TrendingUp, Clock, Brain, DollarSign } from 'lucide-react';
import ScrollAnimation from '../../../../components/common/ScrollAnimation';

const LandingVision = () => {
  return (
    <div className="py-12">
      <ScrollAnimation>
        <h2 className="text-3xl font-bold text-center mb-4 text-white">Transform Your Financial Decision Making</h2>
        <p className="text-gray-400 text-center mb-12 max-w-2xl mx-auto">
          See how AI-powered insights can revolutionize your approach to financial management
        </p>
      </ScrollAnimation>

      <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
        <ScrollAnimation className="bg-gray-800 p-6 rounded-lg shadow-md border border-gray-700">
          <div className="flex items-start space-x-4">
            <div className="bg-blue-900/30 p-3 rounded-lg">
              <Brain className="w-6 h-6 text-blue-400" />
            </div>
            <div>
              <h3 className="font-semibold text-lg text-white mb-2">Small Business Insights</h3>
              <p className="text-gray-300">
                Get instant AI analysis of your business cash flow, expenses, and growth trends. Make data-driven decisions with confidence.
              </p>
              <div className="mt-3">
                <span className="bg-blue-900 text-blue-300 text-xs font-medium px-2.5 py-0.5 rounded">
                  Expense pattern detection
                </span>
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation className="bg-gray-800 p-6 rounded-lg shadow-md border border-gray-700">
          <div className="flex items-start space-x-4">
            <div className="bg-blue-900/30 p-3 rounded-lg">
              <Clock className="w-6 h-6 text-blue-400" />
            </div>
            <div>
              <h3 className="font-semibold text-lg text-white mb-2">Freelancer Time Savings</h3>
              <p className="text-gray-300">
                Automate financial tracking and tax planning. Focus on your work while AI handles the financial analysis.
              </p>
              <div className="mt-3">
                <span className="bg-blue-900 text-blue-300 text-xs font-medium px-2.5 py-0.5 rounded">
                  Smart income tracking
                </span>
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation className="bg-gray-800 p-6 rounded-lg shadow-md border border-gray-700">
          <div className="flex items-start space-x-4">
            <div className="bg-blue-900/30 p-3 rounded-lg">
              <DollarSign className="w-6 h-6 text-blue-400" />
            </div>
            <div>
              <h3 className="font-semibold text-lg text-white mb-2">Personal Finance Control</h3>
              <p className="text-gray-300">
                Get personalized insights and recommendations for better budgeting, saving, and investment decisions.
              </p>
              <div className="mt-3">
                <span className="bg-blue-900 text-blue-300 text-xs font-medium px-2.5 py-0.5 rounded">
                  AI-powered advice
                </span>
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation className="bg-gray-800 p-6 rounded-lg shadow-md border border-gray-700">
          <div className="flex items-start space-x-4">
            <div className="bg-blue-900/30 p-3 rounded-lg">
              <TrendingUp className="w-6 h-6 text-blue-400" />
            </div>
            <div>
              <h3 className="font-semibold text-lg text-white mb-2">Growth Optimization</h3>
              <p className="text-gray-300">
                Identify opportunities and optimize spending with advanced AI analysis across multiple financial dimensions.
              </p>
              <div className="mt-3">
                <span className="bg-blue-900 text-blue-300 text-xs font-medium px-2.5 py-0.5 rounded">
                  Multi-AI analysis
                </span>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>

      <div className="mt-12 text-center">
        <button className="bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors">
          Start Free Trial
        </button>
        <p className="text-gray-400 mt-4 text-sm">
          No Payment required • 7-day free trial • Access Paid features
        </p>
      </div>
    </div>
  );
};

export default LandingVision;