// src/store/thunks/bankThunks.js
import api from '../../services/api';
import {
  setBankLoading,
  setBankError,
  setBankAccounts,
  addBankConnection,
  removeBankConnection
} from '../slices/bankSlice';
import { addNotification } from '../slices/uiSlice';
import { toast } from 'react-hot-toast';

const RETRY_DELAY = 3000; // 3 seconds
const MAX_RETRIES = 2;

export const saveBankConnection = (enrollmentData) => async (dispatch) => {
  try {
    dispatch(setBankLoading(true));
    const response = await api.post('/api/v1/banks/connections', enrollmentData);
    dispatch(addBankConnection(response.data));
    dispatch(addNotification({
      type: 'success',
      message: 'Bank connected successfully!'
    }));
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.detail || 'Failed to save bank connection';
    dispatch(setBankError(errorMessage));
    dispatch(addNotification({
      type: 'error',
      message: errorMessage
    }));
    throw error;
  } finally {
    dispatch(setBankLoading(false));
  }
};

export const fetchBankAccounts = () => async (dispatch) => {
  try {
    dispatch(setBankLoading(true));
    const response = await api.get('/api/v1/banks/accounts');
    dispatch(setBankAccounts(response.data));
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.detail || 'Failed to fetch bank accounts';
    dispatch(setBankError(errorMessage));
    dispatch(addNotification({
      type: 'error',
      message: errorMessage
    }));
    throw error;
  } finally {
    dispatch(setBankLoading(false));
  }
};

export const fetchTransactions = (accountId, dateRange, retryCount = 0) => async (dispatch) => {
  try {
    dispatch(setBankLoading(true));
    
    let url = `/api/v1/banks/accounts/${accountId}/transactions?retry_count=${retryCount}`;
    
    // Add date range parameters if provided
    if (dateRange?.startDate && dateRange?.endDate) {
      url += `&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`;
    }

    const response = await api.get(url);

    // Check for partial data warning
    if (response.data.metadata?.partial_data) {
      toast.warning(
        'Some transactions might be missing due to the large date range. Consider narrowing your search for more accurate results.',
        { duration: 6000 }
      );
    }

    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.detail || 'Failed to fetch transactions';

    // Handle retry cases for timeout or server errors
    if ((error.code === 'ECONNABORTED' || error.response?.status === 500) && retryCount < MAX_RETRIES) {
      const retryAttempt = retryCount + 1;
      toast.loading(
        `Processing large data volume. Retry ${retryAttempt} of ${MAX_RETRIES}...`,
        { duration: RETRY_DELAY }
      );

      // Wait before retrying
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));

      // Retry with incremented count
      return dispatch(fetchTransactions(accountId, dateRange, retryAttempt));
    }

    // Handle specific error types
    if (error.code === 'ECONNABORTED') {
      toast.error('Request timed out. Try reducing your date range or try again.');
    } else if (error.response?.status === 500) {
      toast.error('Server is busy processing data. Please try again in a moment.');
    } else {
      dispatch(setBankError(errorMessage));
      dispatch(addNotification({
        type: 'error',
        message: errorMessage
      }));
    }
    throw error;
  } finally {
    dispatch(setBankLoading(false));
  }
};

export const disconnectBank = (connectionId) => async (dispatch) => {
  try {
    dispatch(setBankLoading(true));
    await api.delete(`/api/v1/banks/connections/${connectionId}`);
    
    // After successful deletion, fetch updated accounts list
    await dispatch(fetchBankAccounts());
    
    dispatch(addNotification({
      type: 'success',
      message: 'Bank disconnected successfully'
    }));
  } catch (error) {
    const errorMessage = error.response?.data?.detail || 'Failed to disconnect bank';
    dispatch(setBankError(errorMessage));
    dispatch(addNotification({
      type: 'error',
      message: errorMessage
    }));
    throw error;
  } finally {
    dispatch(setBankLoading(false));
  }
};