import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { changePlan } from '../../store/thunks/subscriptionThunks';
import { PLAN_FEATURES } from '../../config/planFeatures';
import ChangePlanModal from '../subscription/ChangePlanModal';

const ProgressBar = ({ value = 0, max = 100, colorClass = "bg-blue-500" }) => {
  const percentage = max > 0 ? Math.min((value / max) * 100, 100) : 0;
  const getBackgroundClass = () => {
    if (percentage >= 90) return "bg-red-500";
    if (percentage >= 75) return "bg-yellow-500";
    return colorClass;
  };

  return (
    <div className="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700">
      <div
        className={`${getBackgroundClass()} h-2 rounded-full transition-all duration-300`}
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
};

const UsageIndicator = () => {
  const subscription = useSelector((state) => state.subscription);
  const chats = useSelector((state) => state.chatHistory.chats) || [];
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const dispatch = useDispatch();
  
  // Get plan limits based on subscription
  const getPlanLimits = () => {
    const plan = PLAN_FEATURES[subscription?.currentPlan || 'free'];
    return plan?.limits || {
      max_chats: 10,
      monthly_queries: 50,
      requests_per_day: 10,
      upgrade_threshold: 40
    };
  };

  const planLimits = getPlanLimits();

  // Calculate chats in the last 24 hours
  const getChatsLast24Hours = () => {
    const twentyFourHoursAgo = new Date();
    twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);
    
    return chats.filter(chat => {
      const chatDate = new Date(chat.created_at);
      return chatDate >= twentyFourHoursAgo;
    }).length;
  };

  // Calculate usage
  const dailyChatCount = getChatsLast24Hours();
  const totalMessages = chats.length * 2; // Each chat has 2 messages

  // Show upgrade suggestions for free tier
  const shouldSuggestUpgrade = () => {
    if (subscription?.currentPlan !== 'free') return false;
    
    return totalMessages >= planLimits.upgrade_threshold || 
           dailyChatCount >= (planLimits.requests_per_day * 0.8);
  };

  const getUpgradeMessage = () => {
    if (totalMessages >= planLimits.upgrade_threshold) {
      return "You're getting great value from our service! Upgrade to get more messages per month.";
    }
    if (dailyChatCount >= (planLimits.requests_per_day * 0.8)) {
      return "Almost reached your daily chat limit. Upgrade for unlimited chats!";
    }
    return null;
  };

  const handleChangePlan = async (planId) => {
    try {
      await dispatch(changePlan(planId));
      setShowChangePlanModal(false);
      toast.success('Plan updated successfully!');
    } catch (error) {
      console.error('Error changing plan:', error);
      toast.error('Failed to change plan. Please try again.');
    }
  };

  return (
    <div className="space-y-4 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
      {shouldSuggestUpgrade() && (
        <div className="p-4 bg-blue-50 border border-blue-200 rounded-md dark:bg-blue-900/30 dark:border-blue-700">
          <div className="flex items-center justify-between">
            <p className="text-sm text-blue-800 dark:text-blue-200">
              {getUpgradeMessage()}
            </p>
            <button
              onClick={() => setShowChangePlanModal(true)}
              className="ml-4 px-4 py-2 text-sm font-medium text-blue-800 bg-blue-100 rounded-md hover:bg-blue-200 dark:bg-blue-800 dark:text-blue-100 dark:hover:bg-blue-700"
            >
              View Plans
            </button>
          </div>
        </div>
      )}
      
      {/* <div className="space-y-2">
        <div className="flex justify-between text-sm text-gray-600 dark:text-gray-300">
          <span>Monthly Messages</span>
          <span>{totalMessages}/{planLimits.monthly_queries}</span>
        </div>
        <ProgressBar value={totalMessages} max={planLimits.monthly_queries} />
      </div>

      <div className="space-y-2">
        <div className="flex justify-between text-sm text-gray-600 dark:text-gray-300">
          <span>Daily Chats (Last 24h)</span>
          <span>{dailyChatCount}/{planLimits.requests_per_day}</span>
        </div>
        <ProgressBar value={dailyChatCount} max={planLimits.requests_per_day} />
      </div> */}

      {subscription?.currentPlan === 'free' ? (
        <div className="text-sm text-gray-500 dark:text-gray-400 mt-2">
          You're on the Free plan. Upgrade anytime to get more features!
        </div>
      ) : (
        <div className="text-sm text-gray-500 dark:text-gray-400 mt-2">
          Plan renews: {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
        </div>
      )}

      <ChangePlanModal
        isOpen={showChangePlanModal}
        onClose={() => setShowChangePlanModal(false)}
        onChangePlan={handleChangePlan}
        currentPlan={subscription?.currentPlan}
        subscription={subscription}
      />
    </div>
  );
};

export default UsageIndicator;