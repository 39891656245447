import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';

const demoVideos = [
  {
    id: 2,
    title: "AI Financial Insights",
    url: "chat.mp4",
    thumbnail: "/api/placeholder/800/450"
  },
  {
    id: 1,
    title: "Bank Account Analysis",
    url: "Banks.mp4",
    thumbnail: "/api/placeholder/800/450"
  }
  
  // {
  //   id: 3,
  //   title: "Transaction Tracking",
  //   url: "/demo-videos/transaction-tracking.mp4",
  //   thumbnail: "/api/placeholder/800/450"
  // }
];

const VideoCarousel = () => {
  const [currentVideo, setCurrentVideo] = useState(0);

  const nextVideo = () => {
    setCurrentVideo((prev) => (prev + 1) % demoVideos.length);
  };

  const prevVideo = () => {
    setCurrentVideo((prev) => (prev - 1 + demoVideos.length) % demoVideos.length);
  };

  return (
    <div className="relative w-full h-full">
      {/* Video Container */}
      <div className="relative w-full h-full bg-white rounded-lg overflow-hidden">
        <motion.div
          key={currentVideo}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="w-full h-full"
        >
          <video
            className="w-full h-full object-cover"
            src={demoVideos[currentVideo].url}
            poster={demoVideos[currentVideo].thumbnail}
            controls
          />
        </motion.div>
      </div>

      {/* Navigation Buttons */}
      <button
        onClick={prevVideo}
        className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-full shadow-lg transition-all"
      >
        <ChevronLeftIcon className="w-6 h-6 text-gray-800" />
      </button>
      <button
        onClick={nextVideo}
        className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 hover:bg-white p-2 rounded-full shadow-lg transition-all"
      >
        <ChevronRightIcon className="w-6 h-6 text-gray-800" />
      </button>

      {/* Video Indicators */}
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
        {demoVideos.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentVideo(index)}
            className={`w-2 h-2 rounded-full transition-all ${
              currentVideo === index ? 'bg-blue-600 w-4' : 'bg-gray-300'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

const LandingHero = () => {
  return (
    <div className="pt-24 pb-12 px-4">
      <div className="grid md:grid-cols-2 gap-12 items-center max-w-6xl mx-auto">
        {/* Left Column - Animated Content */}
        <motion.div 
          className="space-y-6"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.p 
            className="text-5xl font-semibold text-blue-600"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            Unlock AI-Powered Financial Insights in Seconds
          </motion.p>

          <motion.h1 
            className="text-4xl md:text-5xl font-bold text-gray-900"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            Your Personal AI Financial Auditor
          </motion.h1>

          <motion.h2 
            className="text-xl text-gray-600"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            Connect your bank accounts and get instant insights, analysis, and answers about your finances powered by multiple AI models
          </motion.h2>

          <div className="flex gap-4">
            <Link to="/signup">
              <motion.button 
                className="bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Get Started Now
              </motion.button>
            </Link>
            <motion.button 
              className="border border-blue-600 text-blue-600 px-8 py-3 rounded-lg hover:bg-blue-50 transition-colors"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Watch Demo
            </motion.button>
          </div>
        </motion.div>

        {/* Right Column - Video Carousel */}
        <motion.div 
          className="rounded-lg overflow-hidden shadow-xl bg-white aspect-video"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <VideoCarousel />
        </motion.div>
      </div>
    </div>
  );
};

export default LandingHero;