// src/pages/Dashboard/components/ChatInput.jsx
import React, { useState } from 'react';
import { SendIcon, CalendarIcon } from 'lucide-react';
import { toast } from 'react-hot-toast';

const ChatInput = ({ 
  value, 
  onChange, 
  onSubmit, 
  isDisabled, 
  disabledReason,
  placeholder,
  onDateRangeChange
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  const validateDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) return true;
    
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    // Check if end date is before start date
    if (end < start) {
      toast.error('End date cannot be before start date');
      return false;
    }

    const diffMonths = (end.getFullYear() - start.getFullYear()) * 12 + 
                      (end.getMonth() - start.getMonth());
    
    // Warn if range is more than 6 months
    if (diffMonths > 6) {
      toast.warning(
        'Large date ranges may take longer to process. Consider reducing the range for faster results.',
        { duration: 5000 }
      );
    }
    
    return true;
  };

  const handleDateChange = (type, value) => {
    const newRange = { ...dateRange, [type]: value };
    setDateRange(newRange);
    
    if (newRange.startDate && newRange.endDate) {
      if (validateDateRange(newRange.startDate, newRange.endDate)) {
        onDateRangeChange(newRange);
      }
    }
  };

  return (
    <div className="flex flex-col space-y-3 px-4 py-3">
      {/* Date Range Section */}
      <div className="flex flex-col sm:flex-row sm:items-center gap-3">
        <button
          type="button"
          onClick={() => setShowDatePicker(!showDatePicker)}
          className={`flex items-center gap-2 text-sm px-3 py-1.5 rounded-md transition-colors ${
            dateRange.startDate && dateRange.endDate 
              ? 'text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/30'
              : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
          }`}
        >
          <CalendarIcon className="h-4 w-4" />
          <span className="truncate">
            {dateRange.startDate && dateRange.endDate 
              ? `${dateRange.startDate} to ${dateRange.endDate}`
              : 'Filter by Date'}
          </span>
        </button>

        {showDatePicker && (
          <div className="flex flex-col sm:flex-row gap-3 w-full sm:w-auto">
            <div className="flex-1 sm:flex-initial">
              <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">From</label>
              <input
                type="date"
                value={dateRange.startDate}
                onChange={(e) => handleDateChange('startDate', e.target.value)}
                className="w-full sm:w-auto rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors"
              />
            </div>
            <div className="flex-1 sm:flex-initial">
              <label className="block text-sm text-gray-600 dark:text-gray-400 mb-1">To</label>
              <input
                type="date"
                value={dateRange.endDate}
                onChange={(e) => handleDateChange('endDate', e.target.value)}
                className="w-full sm:w-auto rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors"
              />
            </div>
          </div>
        )}
      </div>

      {/* Message Input Section */}
      <form onSubmit={onSubmit} className="relative mt-2">
        <input
          type="text"
          value={value}
          onChange={onChange}
          disabled={isDisabled}
          placeholder={disabledReason || placeholder}
          className="w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 px-4 py-3 pr-12 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:bg-gray-100 dark:disabled:bg-gray-800 transition-colors"
        />
        <button
          type="submit"
          disabled={isDisabled || !value.trim()}
          className="absolute right-2 top-1/2 -translate-y-1/2 rounded-md p-2 text-gray-400 hover:text-gray-600 disabled:opacity-50 transition-colors"
        >
          <SendIcon className="h-5 w-5" />
        </button>
      </form>
    </div>
  );
};

export default ChatInput;