// components/AccountSelector.jsx
import { useState, useCallback } from 'react';
import { toast } from 'react-hot-toast';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const AccountSelector = ({ accounts, selectedAccount, onSelect, isLoading }) => {
  const [retryCount, setRetryCount] = useState(0);

  const handleSelect = useCallback(async (accountId) => {
    const attempt = async () => {
      try {
        const account = accounts.find(acc => acc.id === accountId);
        if (!account) {
          throw new Error('Account not found');
        }
        await onSelect(account);
        setRetryCount(0); // Reset retry count on success
      } catch (error) {
        if (retryCount < MAX_RETRIES) {
          setRetryCount(prev => prev + 1);
          toast.error(`Selection failed, retrying... (${retryCount + 1}/${MAX_RETRIES})`);
          await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
          return attempt(); // Retry
        } else {
          toast.error('Failed to select account. Please try again.');
          setRetryCount(0); // Reset retry count
          throw error;
        }
      }
    };

    try {
      await attempt();
    } catch (error) {
      console.error('Account selection failed:', error);
    }
  }, [accounts, onSelect, retryCount]);

  return (
    <div className="relative">
      <select
        value={selectedAccount?.id || ''}
        onChange={(e) => handleSelect(e.target.value)}
        disabled={isLoading}
        className="block w-60 rounded-md border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 py-2 px-3 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 text-gray-900 dark:text-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <option value="">Select bank account</option>
        {accounts.map(account => (
          <option 
            key={account.id} 
            value={account.id}
            disabled={account.status === 'inactive'}
          >
            {account.bank_name} - {account.account_name} (*{account.account_number_last4})
          </option>
        ))}
      </select>
      {isLoading && (
        <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
          <div className="animate-spin h-4 w-4 border-2 border-blue-500 rounded-full border-t-transparent"></div>
        </div>
      )}
    </div>
  );
};

export default AccountSelector;