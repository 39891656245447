// src/pages/Dashboard/components/ChatHeader.jsx
import AccountSelector from './AccountSelector';
import ModelSelector from './ModelSelector';
import { History } from 'lucide-react';
import { MODEL_REQUIREMENTS } from '../../../components/guards/SubscriptionGuard';
import UsageIndicator from '../../../components/subscription/UsageIndicator';


const ChatHeader = ({ 
  selectedAccount, 
  selectedModel, 
  onAccountSelect, 
  onModelSelect, 
  accounts, 
  models,
  currentPlan, 
  isLoading, 
  onHistoryClick 
}) => {
  // Function to get required plan name for display
  const getRequiredPlan = (modelId) => {
    const requiredPlans = MODEL_REQUIREMENTS[modelId];
    if (!requiredPlans) return 'upgrade';
    
    // Find the lowest tier required plan
    const planOrder = ['basic', 'pro', 'enterprise'];
    return requiredPlans.sort(
      (a, b) => planOrder.indexOf(a) - planOrder.indexOf(b)
    )[0];
  };

  // Enhance models with availability info
  const modelsWithAvailability = models.map(model => ({
    ...model,
    isAvailable: MODEL_REQUIREMENTS[model.id]?.includes(currentPlan),
    requiredPlan: getRequiredPlan(model.id)
  }));

  return (
    <div className="bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 p-4">
      <div className="max-w-4xl mx-auto flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <button
            onClick={onHistoryClick}
            className="p-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            title="Chat History"
          >
            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white">Financial Assistant</h1>
        </div>
        
        <div className="flex gap-4">
          <AccountSelector 
            accounts={accounts}
            selectedAccount={selectedAccount}
            onSelect={onAccountSelect}
            isLoading={isLoading}
          />
          <ModelSelector 
            models={modelsWithAvailability}
            selectedModel={selectedModel}
            onSelect={onModelSelect}
            currentPlan={currentPlan}
          />
        </div>
      </div>

      {/* Add Usage Indicator */}
      <div className="mt-4">
          <UsageIndicator />
        </div>
    </div>
  );
};

export default ChatHeader;