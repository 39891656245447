// store/slices/paymentMethodSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  card: null,
  loading: false,
  error: null
};

const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState,
  reducers: {
    setPaymentMethod: (state, action) => {
      state.card = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearPaymentMethod: (state) => {
      state.card = null;
    }
  }
});

export const {
  setPaymentMethod,
  setLoading,  
  setError,
  clearPaymentMethod
} = paymentMethodSlice.actions;

export const selectPaymentMethod = (state) => state.paymentMethod.card;

export default paymentMethodSlice.reducer;