import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBillingHistory } from '../../store/thunks/subscriptionThunks';
import { DownloadIcon } from 'lucide-react';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import api from '../../services/api';

const BillingHistory = () => {
  const dispatch = useDispatch();
  const [billingHistory, setBillingHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const subscription = useSelector(state => state.subscription);

  useEffect(() => {
    const fetchBillingHistory = async () => {
      if (subscription?.currentPlan === 'free') {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const response = await dispatch(getBillingHistory());
        setBillingHistory(response);
      } catch (err) {
        setError(err.message || 'Failed to fetch billing history');
      } finally {
        setIsLoading(false);
      }
    };

    fetchBillingHistory();
  }, [dispatch, subscription?.currentPlan]);

  const downloadInvoice = async (invoiceId) => {
    try {
      const loadingToast = toast.loading('Downloading invoice...');
      const response = await api.get(`/api/v1/subscription/invoice/${invoiceId}`);
      toast.dismiss(loadingToast);
      window.open(response.data.url, '_blank');
      toast.success('Invoice downloaded successfully');
    } catch (error) {
      toast.error(error.response?.data?.detail || 'Failed to download invoice');
    }
  };

  // Show message for free tier users
  if (subscription?.currentPlan === 'free') {
    return (
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
            Billing History
          </h3>
          <div className="mt-4 text-sm text-gray-500 dark:text-gray-400">
            Billing history is available for paid plans only. 
            Upgrade to a premium plan to view your billing history.
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-1/4 mb-4"></div>
        {[1, 2, 3].map((i) => (
          <div key={i} className="h-16 bg-gray-100 dark:bg-gray-800 rounded mb-2"></div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 dark:bg-red-900/30 p-4">
        <div className="flex">
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800 dark:text-red-200">
              Error loading billing history
            </h3>
            <div className="mt-2 text-sm text-red-700 dark:text-red-300">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (billingHistory.length === 0) {
    return (
      <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
            Billing History
          </h3>
          <div className="mt-4 text-sm text-gray-500 dark:text-gray-400">
            No billing history found. Your first invoice will appear here after your first payment.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
          Billing History
        </h3>
        
        <div className="mt-4">
          <div className="-mx-4 sm:-mx-6 lg:-mx-8">
            <div className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Date
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Description
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Amount
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                  {billingHistory.map((item) => (
                    <React.Fragment key={item.id}>
                      <tr 
                        className="hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer"
                        onClick={() => setExpandedRow(expandedRow === item.id ? null : item.id)}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                          {format(new Date(item.date), 'MMM d, yyyy')}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                          {item.description}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                          ${item.amount.toFixed(2)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            item.status === 'paid' 
                              ? 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-200' 
                              : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-200'
                          }`}>
                            {item.status}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              downloadInvoice(item.id);
                            }}
                            className="text-blue-600 dark:text-blue-400 hover:text-blue-900 dark:hover:text-blue-300"
                          >
                            <DownloadIcon className="h-5 w-5" />
                          </button>
                        </td>
                      </tr>
                      {expandedRow === item.id && (
                        <tr>
                          <td colSpan="5" className="px-6 py-4 bg-gray-50 dark:bg-gray-700">
                            <div className="text-sm text-gray-900 dark:text-gray-100">
                              <h4 className="font-medium mb-2">Transaction Details</h4>
                              <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                                <div>
                                  <dt className="text-gray-500 dark:text-gray-400">Invoice Number</dt>
                                  <dd className="mt-1">{item.invoice_number}</dd>
                                </div>
                                <div>
                                  <dt className="text-gray-500 dark:text-gray-400">Payment Method</dt>
                                  <dd className="mt-1">•••• {item.last4}</dd>
                                </div>
                                {item.proration && (
                                  <div className="col-span-2">
                                    <dt className="text-gray-500 dark:text-gray-400">Proration Details</dt>
                                    <dd className="mt-1">{item.proration_details}</dd>
                                  </div>
                                )}
                              </dl>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingHistory;