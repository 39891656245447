import React from 'react';
import DashboardLayout from '../../components/Layouts/DashboardLayout';

const sections = {
  introduction: {
    title: 'Introduction',
    content: `
      Effective Date: ${new Date().toLocaleDateString()}

      If you are our customer, this privacy notice applies to you. It explains what data we collect about you, what we do with it, when and why we share it with others, how long we keep it, how we secure it, what cookies are and what they (and similar technologies) do, what data is collected about you by third party service providers, and what choices you have to control your data.

      By using our services you consent to these data practices.
    `
  },
  informationWeCollect: {
    title: 'Information We Collect About You',
    content: `
      We need to collect information about you to provide you with the Services or the support you request. Specifically, we collect (and/or have collected during at least the 12-month period preceding the effective date of this Privacy Notice) the categories of personal information described below. Additionally, you can choose to voluntarily provide information to us.

      ### Information You Provide to Access our Services

      We collect information you provide when you:
      - Apply or sign up for an account
      - Go through our identity or account verification process
      - Authenticate into your account
      - Communicate with us
      - Answer our surveys
      - Participate in contests or promotions offered by us or our partners
      - Otherwise use our Services

      #### We collect the following categories of information:

      ##### Identification Information
      - Your name
      - Email address
      - Zip or postal code
      - Phone number
      - Username
      - Authentication credentials
      - Internet Protocol ("IP") address

      ##### Additional Identification Information for Verified Users
      - Your full mailing address
      - Date of birth
      - Government-issued identification, including:
        - Social Security number
        - Passport
        - Driver's license
      - Photograph or facial scan when required for verification
      - Biometrics extracted from such images if you choose to share them

      ##### Financial Information
      - Bank account numbers
      - Payment card numbers
      - Financial account details

      ##### Contacts Information
      To provide you with the Service, we will also need to collect information about intended recipients of payments. This includes:
      - Contact details of your intended recipients
      - Phone numbers
      - Email addresses
      - Optional access to your phone contacts information

      ##### Transaction Information
      When you use our Services to make, accept, or request payments, we collect information such as:
      - When and where transactions occur
      - Names of the transacting parties
      - Description of transactions
      - Payment or transfer amounts
      - Devices and payment methods used

      ### Information You Provide to Enhance Your Experience

      You can choose to provide us with additional information to improve your user experience:

      ##### Contacts Information
      You can choose to let us access and upload your phone contacts information to:
      - Make it easier to find people you want to send money to
      - Help you invite your friends to our service
      - Account and identity verification
      - Fraud prevention purposes
      - Reduce the risk of sending payments to wrong recipients
      - Provide personalized services

      ### Information We Collect From Your Use of our Services

      ##### Device Information
      - Hardware model
      - Operating system and version
      - Device name
      - Unique device identifier
      - Mobile network information
      - Browser type and settings
      - Language preferences

      ##### Internet or Network Activity Information
      - Access time and duration
      - "Log-in" and "log-out" information
      - Browser type and language
      - Country and language settings
      - IP address
      - Internet service provider information
      - Pages visited on our platform
      - Features used
      - Content viewed
      - Search terms
      - Referring websites
    `
  },
  sourcesOfInformation: {
    title: 'Sources of Information We Collect About You',
    content: `
      We collect information about you from the following categories of sources:

      - You directly, when you submit information to us or allow us to access information
      - Your devices and how you interact with our Services
      - Our group companies or affiliates
      - Other sources, including:
        - Identity verification services
        - Credit reporting agencies
        - Public databases
        - Marketing partners
        - Social media platforms
    `
  },
  howWeUseInformation: {
    title: 'How We Use Your Information',
    content: `
      ### Providing, Improving, and Developing our Services
      - Determining service availability in your area
      - Processing and recording transactions
      - Providing customer support
      - Developing new features
      - Improving user experience
      - Conducting research and analytics
      - Managing user accounts

      ### Security and Compliance
      - Verifying identity
      - Detecting and preventing fraud
      - Managing risk
      - Enforcing our terms and policies
      - Complying with legal obligations
      - Responding to legal requests
    `
  },
  cookiesAndTechnologies: {
    title: 'Cookies and Other Similar Automated Technologies',
    content: `
      When you interact with our online services, or open emails we send you, we obtain certain information using automated technologies, such as:
      - Cookies
      - Web server logs
      - Web beacons
      - Pixel tags
      - Clear GIFs
    `
  },
  dataRetention: {
    title: 'How Long We Keep Your Information',
    content: `
      We retain your information for as long as necessary to:
      - Provide our services
      - Comply with legal obligations
      - Resolve disputes
      - Enforce agreements
      - Support business operations
      - Continue to develop and improve our services
    `
  },
  yourChoicesAndRights: {
    title: 'Your Choices and Rights',
    content: `
      You have the right to:
      - Access your personal information
      - Correct your personal information
      - Delete your personal information
      - Port your data to another service
      - Opt-out of certain data sharing
      - Control your privacy settings
      - Manage your marketing preferences
    `
  },
  security: {
    title: 'Security',
    content: `
      We maintain appropriate administrative, technical, and physical safeguards designed to protect your personal information against accidental, unlawful, or unauthorized destruction, loss, alteration, access, disclosure, or use. These include:
      - Encryption of data in transit and at rest
      - Firewalls and access controls
      - Security monitoring systems
      - Secure data centers
      - Regular security assessments
      - Employee security training
      - Incident response plans
    `
  },
  storageAndProcessing: {
    title: 'Storage and Processing',
    content: `
      We may, and we may use third-party service providers to, process and store your information in:
      - The United States
      - The European Union
      - The United Kingdom
      - Other countries where we or our service providers operate
    `
  },
  californiaResidents: {
    title: 'Rights of California Residents',
    content: `
      If you live in California, the following additional rights apply to you.

      ### Right to Know
      You may have the right to request, up to twice in a 12-month period, to see the following information:
      - The categories and specific pieces of personal information we have collected about you
      - The categories of sources from which we collected the information
      - The business or commercial purpose for collecting the information
      - The categories of third parties with whom we shared the information
      - The categories of personal information disclosed for business purposes

      ### Right of Deletion
      You have the right to request that we delete personal information we have collected from you, subject to certain exceptions provided by law.

      ### Right of Correction
      You have the right to request correction of inaccurate personal information maintained about you.

      ### Right to Non-Discrimination
      You have the right not to be discriminated against for exercising any of your California privacy rights.
    `
  },
  childrensPrivacy: {
    title: "Children's Personal Information",
    content: `
      Our Services are general audience services not directed at children under the age of 13. We do not knowingly:
      - Collect personal information from children under 13
      - Sell or share personal information of children under 16
      - Target our services to children under 13
    `
  },
  changes: {
    title: 'Changes to this Privacy Notice',
    content: `
      We may amend this Privacy Notice from time to time by posting a revised version and updating the "Effective Date" above. The revised version will be effective on the "Effective Date" listed. We will provide you with reasonable prior notice of material changes in how we use your information, including:
      - Notification through our app
      - Email notification if you've provided an email
      - Website notifications
      - Other appropriate communication channels
    `
  },
  contact: {
    title: 'How to Contact Us',
    content: `
      Please contact our privacy team with any questions or concerns regarding this Privacy Notice:

      [Finjy]
      [PO BOX 1515]
      [San Diego, CA 92115]
      Email: privacy@finjy.com
      Phone: [619-549-8237]

      If you have any questions or concerns regarding our privacy notice, or if you believe our privacy notice or applicable laws relating to the protection of your personal information have not been respected, you may file a complaint with our privacy team listed above. We will respond to let you know when you can expect a further response. We may request additional details from you regarding your concerns and may need to engage or consult with other parties in order to investigate and address your issue. We may keep records of your request and any resolution.
    `
  }
};

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
        
        {Object.entries(sections).map(([key, section]) => (
          <div key={key} className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">{section.title}</h2>
            <div 
              className="prose prose-blue max-w-none"
              dangerouslySetInnerHTML={{ __html: section.content.split('\n').join('<br/>') }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;