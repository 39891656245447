import React from 'react';
import DashboardLayout from '../../components/Layouts/DashboardLayout';

const sections = {
  overview: {
    title: '1. Overview and Acceptance of Terms',
    content: `
      ### 1.1 Agreement to Terms

      By accessing or using the Friendstabs application, website, and services (collectively referred to as the "Service"), you acknowledge that you have read, understood, and agree to be bound by these Terms of Service ("Terms"). These Terms constitute a legally binding agreement between you and Friendstabs Inc. ("Friendstabs," "we," "us," or "our"). If you do not agree with any part of these Terms, you are prohibited from accessing or using the Service.

      ### 1.2 Modifications to Terms

      Friendstabs reserves the right to modify these Terms at any time at its sole discretion. We will notify you of any changes by posting the updated Terms on our website and updating the "Last Modified" date. Your continued use of the Service following the posting of modified Terms constitutes your acceptance of the updated Terms.

      ### 1.3 Eligibility

      To use our Service, you must be at least 18 years of age and capable of forming a legally binding contract. By using the Service, you represent and warrant that you meet these eligibility requirements.

      ### 1.4 Privacy Policy

      Your use of the Service is also governed by our Privacy Policy, which is incorporated into these Terms by reference. Please review our Privacy Policy to understand our practices regarding the collection, use, and disclosure of your personal information.
    `
  },
  accountTerms: {
    title: '2. Account Terms',
    content: `
      ### 2.1 Account Registration

      To access certain features of the Service, you must register for an account. You agree to:
      - Provide accurate, current, and complete information during the registration process
      - Maintain and promptly update your account information
      - Keep your account credentials secure and confidential
      - Notify us immediately of any unauthorized access to your account
      - Accept responsibility for all activities that occur under your account

      ### 2.2 Account Types

      #### 2.2.1 Personal Accounts
      - Intended for individual use only
      - May not be used for commercial purposes
      - Subject to personal account transaction limits

      #### 2.2.2 Business Accounts
      - Required for commercial use of the Service
      - Subject to additional verification requirements
      - Different fee structure and transaction limits apply
      - Must provide valid business documentation as requested

      ### 2.3 Account Verification

      We may require various forms of identification to verify your identity, which may include:
      - Government-issued photo ID
      - Proof of address
      - Social Security number or Tax ID
      - Additional documentation as deemed necessary by Friendstabs

      ### 2.4 Account Restrictions

      Friendstabs reserves the right, at its sole discretion, to:
      - Place holds on transactions
      - Limit account functionality
      - Suspend or terminate accounts
      - Request additional verification at any time
    `
  },
  conditions: {
    title: '3. Conditions',
    content: `
      ### 3. Conditions

      The Customer is a legal entity acting through a natural person with the power or 
      authority required to enter into a contract in the Customer's name and on their behalf.
      The Customer is a professional, understood as any natural person or legal entity acting 
      for purposes within the scope of their commercial, industrial, artisanal, liberal or 
      agricultural activity, including when acting in the name of or on behalf of another professional.
    `
  },
  OrderAccesstoServices: {
    title: `4. Order of the Services and access to the Services`,
    content: `
      ### 4.1 Order of the Services and access to the Services
      The Customer subscribes to the Services on a subscription basis (the "Subscription"). 
      Each subscription gives the right to a number of queries corresponding to a number 
      of queries that can be generated on the Platform (the "queries"). 
      The number of queries is indicated in the Offer.
      
      The Customer must enter their email address or be registered on one of the third-party websites
      listed on the Platform and use the login details of the selected third-party website. 
      The Customer must then complete the form available on the Platform and provide FINJI 
      with all information marked as mandatory.Once the form has been completed, the Customer may 
      either subscribe directly to the Services on the Platform or will be put in contact with FINJI
       to draw up a Customized offers ‘Subscription.
    `
  },
  security: {
    title: '5. Security and Fraud Prevention',
    content: `
      ### 5.1 Account Security

      #### 5.1.1 User Responsibilities
      You are responsible for:
      - Maintaining confidentiality of your account credentials
      - Using strong, unique passwords
      - Enabling two-factor authentication when available
      - Reporting any suspicious activity immediately
      - Logging out from shared devices

      #### 5.1.2 Security Features
      Friendstabs implements various security measures, including:
      - Two-factor authentication
      - Biometric login options
      - Transaction notifications
      - Suspicious activity monitoring
      - Device verification

      ### 5.2 Fraud Prevention

      #### 5.2.1 Monitoring
      We monitor accounts for:
      - Unusual transaction patterns
      - Multiple failed login attempts
      - Suspicious device activity
      - Geographic anomalies
      - High-risk transaction patterns

      #### 5.2.2 Fraud Protection Measures
      - Transaction limits
      - Hold periods on suspicious transfers
      - Identity verification requirements
      - Real-time fraud detection algorithms
      - Ongoing account activity analysis

      ### 5.3 Unauthorized Transactions

      #### 5.3.1 Reporting Requirements
      - Report unauthorized transactions within 24 hours
      - Provide detailed information about disputed transactions
      - Cooperate with our investigation procedures
      - Submit any required documentation promptly

      #### 5.3.2 Investigation Process
      - Initial review within 24 hours of report
      - Temporary credit decision within 10 business days
      - Final resolution within 45 days
      - Written explanation of findings provided
    `
  },
  Maintenance: {
    title: `6. Maintenance, hosting, and technical support`,
    content: `
      ### 6.1 Maintenance
      For the duration of the Services, the Customer benefits from maintenance, in particular corrective and ongoing maintenance.
      In this context, access to the Platform may be limited or suspended.
      FINJI makes every effort to provide the Customer with corrective maintenance to correct any malfunction or bug found on the Platform.
      The Customer also benefits from ongoing maintenance, which FINJI may carry out automatically and without prior notice, 
      and which includes improvements to the Platform's functionalities, the addition of new functionalities and/or technical
      installations used within the framework of the Platform (aiming to introduce minor or major extensions). 
      FINJI reserves the right to charge for certain functions.Access to the Platform may also be limited or suspended
      for planned maintenance purposes, which may include the corrective and ongoing maintenance operations referred to above.

      ### 6.2 Hosting
      FINJI uses its best efforts to host the Platform, as well as the data produced on the Platform, 
      via a professional hosting service provider, and on servers located in a territory of the United States.

      ### 6.2 Technical support
      In the event of any difficulty encountered while using our Services, the Customer may contact FINJI directly on the Platform.
      Technical support service is available from Monday to Friday, excluding French public holidays, from 9 am to 6 pm, PST time.
      Depending on the need identified, FINJI will estimate the response time and inform the Customer accordingly.

      ### 6.3 Modification of the Offer
      If the Customer wishes to modify the content of its Subscription (and in particular the number of Credits), they must:
      If the Customer wants to upgrade the Offer
      If the Customer has a Standard offer: the Customer can choose another Standard offer or contact FINJI to have a Customized offer.
      If the Customer has a Customized Offer, the Customer must contact FINJI to obtain a new Customized Offer.
      When the Customer upgrades from one Offer to another superior, the Subscription they had ends on the day they subscribe to the new upgrade Offer, 
      which then begins on the day of payment. If the Customer has not used all the Credits of their current Subscription at the time of subscribing to the new, superior Offer, the remaining Credits are carried over to the new subscription period subscribed to.
      If the Customer wants to downgrade the Offer
      If the Customer has a Standard offer: the Customer can choose another Standard offer or contact FINJI to have a Customized offer.
      If the Customer has a Customized Offer, the Customer must contact FINJI to obtain a new Customized Offer.
      When the Customer downgrade from one Offer to another lower, the new Offer subscribed will begin on the renewal date of the 
      Subscription following the initial subscription period. If the Customer has not used all of the Credits of their current 
      Subscription at the end of the Period, Credits will be lost and cannot be carried forward or refunded.
    `
  },
  privacy: {
    title: '7. Privacy and Data Protection',
    content: `
      ### 7.1 Data Collection

      #### 7.1.1 Information We Collect
      - Personal identification information
      - Transaction data
      - Device information
      - Usage patterns
      - Communication records

      #### 7.1.2 Collection Methods
      - Direct user input
      - Automatic collection through Service use
      - Third-party sources
      - Public records
      - Partner information sharing

      ### 7.2 Data Usage

      #### 7.2.1 Primary Uses
      - Processing transactions
      - Account maintenance
      - Security and fraud prevention
      - Service improvement
      - Legal and regulatory compliance

      #### 7.2.2 Secondary Uses
      - Analytics and reporting
      - Marketing (with user consent)
      - Product development
      - Research purposes
      - Partner services integration

      ### 7.3 Data Protection

      #### 7.3.1 Security Measures
      - Encryption of sensitive data
      - Access controls and authentication
      - Regular security audits
      - Employee training on data protection
      - Incident response plans

      #### 7.3.2 Data Retention
      - Transaction records: 7 years
      - Account information: Duration of account plus 2 years
      - Marketing data: 2 years from last interaction
      - Security logs: 1 year
    `
  },
  termination: {
    title: '8. Account Termination',
    content: `
      ### 8.1 Voluntary Termination

      #### 8.1.1 User-Initiated Termination
      - Written notice required for account closure
      - Account settlement process
      - Data retention requirements post-closure
      - Final statement provision

      #### 8.1.2 Termination Process
      - Review of pending transactions
      - Balance settlement procedures
      - Account closure confirmation
      - Data handling notification

      ### 8.2 Involuntary Termination

      #### 8.2.1 Grounds for Termination
      - Violation of these Terms
      - Suspicious or fraudulent activity
      - Extended period of account inactivity
      - Legal or regulatory requirements
      - Risk assessment outcomes

      #### 8.2.2 Termination Process
      - Notice period, if applicable
      - Appeal process availability
      - Fund disposition procedures
      - Data retention and handling post-termination
    `
  },
  liability: {
    title: '9. Limitation of Liability',
    content: `
      ### 9.1 General Limitations

      #### 9.1.1 Scope of Liability
      - Limited to direct damages only
      - Maximum liability caps
      - Excluded damages categories
      - Force majeure event considerations

      #### 9.1.2 Exceptions to Limitations
      - Gross negligence
      - Willful misconduct
      - Statutory requirements
      - Fraudulent activities

      ### 9.2 Specific Limitations

      #### 9.2.1 Transaction-Related Limitations
      - Processing errors
      - Network failures or outages
      - Third-party actions or omissions
      - User errors or oversights

      #### 9.2.2 Service-Related Limitations
      - Service availability issues
      - Feature modifications or removals
      - Performance problems or degradation
      - Integration failures with third-party services
    `
  }
};

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms of Service</h1>
        
        {Object.entries(sections).map(([key, section]) => (
          <div key={key} className="mb-12">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">{section.title}</h2>
            <div 
              className="prose prose-blue max-w-none"
              dangerouslySetInnerHTML={{ __html: section.content.split('\n').join('<br/>') }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsOfService;