import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
const isProd = () => window.location.hostname === 'finjy.com' && MEASUREMENT_ID;

export const AnalyticsProvider = ({ children }) => {
  const location = useLocation();

  // Track page views
  useEffect(() => {
    if (isProd() && window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  if (!isProd()) {
    return children;
  }

  return (
    <>
      <Helmet>
        {/* Google tag (gtag.js) */}
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${MEASUREMENT_ID}`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${MEASUREMENT_ID}');
          `}
        </script>
      </Helmet>
      {children}
    </>
  );
};

export const trackEvent = (eventName, params = {}) => {
  if (isProd() && window.gtag) {
    window.gtag('event', eventName, params);
  }
};