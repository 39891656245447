const ChatMessages = ({ messages, isLoading }) => {
  return (
    <div className="flex-1 overflow-y-auto p-4 space-y-6">
      {messages.map((message, index) => (
        <div
          key={index}
          className={`flex ${
            message.role === 'assistant' 
              ? 'bg-white dark:bg-gray-800' 
              : 'bg-gray-50 dark:bg-gray-700'
          } p-6 rounded-lg shadow-sm`}
        >
          <div className={`flex-shrink-0 w-8 h-8 rounded-full ${
            message.role === 'assistant' ? 'bg-green-500' : 'bg-blue-500'
          } flex items-center justify-center text-white text-sm font-semibold`}>
            {message.role === 'assistant' ? 'AI' : 'U'}
          </div>
          <div className="ml-4 flex-1">
            <p className="text-gray-900 dark:text-gray-100">{message.content}</p>
          </div>
        </div>
      ))}
      {isLoading && (
        <div className="flex items-center justify-center py-4">
          <div className="animate-pulse text-gray-500 dark:text-gray-400">AI is thinking...</div>
        </div>
      )}
    </div>
  );
};

export default ChatMessages;