// store/slices/feedbackSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  submissions: [],
  loading: false,
  error: null
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setFeedbackLoading: (state, action) => {
      state.loading = action.payload;
      if (action.payload) {
        state.error = null;
      }
    },
    setFeedbackError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    addFeedbackSubmission: (state, action) => {
      state.submissions.push(action.payload);
      state.loading = false;
      state.error = null;
    }
  }
});

export const {
  setFeedbackLoading,
  setFeedbackError,
  addFeedbackSubmission
} = feedbackSlice.actions;

export const selectFeedbackSubmissions = (state) => state.feedback.submissions;
export const selectFeedbackLoading = (state) => state.feedback.loading;
export const selectFeedbackError = (state) => state.feedback.error;

export default feedbackSlice.reducer;