import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../hooks/useRedux';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle, CheckCircle, Clock, ArrowUpCircle, XIcon } from 'lucide-react';
import { dismissBanner, selectDismissedBanners } from '../../store/slices/uiPreferencesSlice';
import { PLAN_FEATURES } from '../../config/planFeatures';

const SubscriptionStatus = () => {
  const dispatch = useDispatch();
  const dismissedBanners = useSelector(selectDismissedBanners);
  const navigate = useNavigate();
  const subscription = useAppSelector(state => state.subscription);
  const usage = useAppSelector(state => state.analytics?.usage);
  const paymentMethod = useAppSelector(state => state.paymentMethod);

  if (dismissedBanners.includes('subscription-status')) return null;
  if (!subscription) return null;

  // Ensure proper plan assignment - default to 'free' if plan is invalid
  const currentPlan = PLAN_FEATURES[subscription.currentPlan] ? subscription.currentPlan : 'free';
  const status = subscription.status || 'active';  // Default to active for free tier
  const { currentPeriodEnd, cancelAtPeriodEnd } = subscription;
  const isFree = currentPlan === 'free';

  const getUsagePercentage = () => {
    if (!usage || !isFree) return null;
    const limits = PLAN_FEATURES.free.limits;
    const monthlyUsage = usage.monthly_queries || 0;
    return Math.round((monthlyUsage / limits.monthly_queries) * 100);
  };

  const usagePercentage = getUsagePercentage();

  const getStatusDisplay = () => {
    if (isFree) {
      return {
        icon: <CheckCircle className="h-5 w-5 text-green-500" />,
        text: 'Free Plan',
        description: usagePercentage ? 
          `You've used ${usagePercentage}% of your monthly limit. Upgrade for unlimited usage.` :
          'Get started with basic features. Upgrade anytime for more capabilities.',
        className: 'bg-blue-50 dark:bg-blue-900/30 border-blue-200 dark:border-blue-800'
      };
    }

    if (cancelAtPeriodEnd) {
      return {
        icon: <AlertTriangle className="h-5 w-5 text-yellow-500" />,
        text: 'Canceling Soon',
        description: `Your subscription will end on ${new Date(currentPeriodEnd).toLocaleDateString()}.`,
        className: 'bg-yellow-50 dark:bg-yellow-900/30 border-yellow-200 dark:border-yellow-800'
      };
    }

    if (status === 'active') {
      return {
        icon: <CheckCircle className="h-5 w-5 text-green-500" />,
        text: 'Active',
        description: `Your ${PLAN_FEATURES[currentPlan]?.name || ''} plan renews on ${new Date(currentPeriodEnd).toLocaleDateString()}.`,
        className: 'bg-green-50 dark:bg-green-900/30 border-green-200 dark:border-green-800'
      };
    }

    return {
      icon: <AlertTriangle className="h-5 w-5 text-red-500" />,
      text: 'Inactive',
      description: 'Your subscription is inactive. Upgrade to a paid plan to access premium features.',
      className: 'bg-red-50 dark:bg-red-900/30 border-red-200 dark:border-red-800'
    };
  };

  const statusDisplay = getStatusDisplay();
  const showUpgradeButton = isFree || status === 'inactive';
  const showManageButton = !isFree && status === 'active' && !cancelAtPeriodEnd;
  const showReactivateButton = !isFree && (cancelAtPeriodEnd || status === 'inactive');

  const planInfo = PLAN_FEATURES[currentPlan];

  return (
    <div className={`relative rounded-lg border p-4 ${statusDisplay.className}`}>
      <button
        onClick={() => dispatch(dismissBanner('subscription-status'))}
        className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
      >
        <XIcon className="h-5 w-5" />
      </button>

      <div className="flex items-start space-x-3">
        <div className="flex-shrink-0">
          {statusDisplay.icon}
        </div>
        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-gray-900 dark:text-white">
              {statusDisplay.text}
            </p>
            {planInfo && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                {planInfo.name}
              </span>
            )}
          </div>
          
          <p className="mt-1 text-sm text-gray-500">
            {statusDisplay.description}
          </p>

          {isFree && usagePercentage && (
            <div className="mt-3">
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div 
                  className={`h-2 rounded-full transition-all duration-300 ${
                    usagePercentage > 90 ? 'bg-red-500' : 
                    usagePercentage > 70 ? 'bg-yellow-500' : 
                    'bg-green-500'
                  }`}
                  style={{ width: `${Math.min(usagePercentage, 100)}%` }}
                />
              </div>
            </div>
          )}

          {planInfo && (
            <div className="mt-3">
              <h4 className="text-sm font-medium text-gray-900">Current Features:</h4>
              <ul className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-2">
                {planInfo.features.featureList.map((feature, index) => (
                  <li key={index} className="flex items-center text-sm text-gray-500">
                    <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="mt-4 flex space-x-3">
            {showUpgradeButton && (
              <button
                onClick={() => navigate('/upgrade')}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {usagePercentage > 70 ? 'Upgrade Now' : 'View Premium Features'}
              </button>
            )}
            {showManageButton && (
              <button
                onClick={() => navigate('/subscription')}
                className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Manage Subscription
              </button>
            )}
            {showReactivateButton && (
              <button
                onClick={() => navigate('/subscription')}
                className="inline-flex items-center px-3 py-2 border border-blue-300 text-sm leading-4 font-medium rounded-md text-blue-700 bg-blue-50 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <ArrowUpCircle className="h-4 w-4 mr-1" />
                Reactivate
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionStatus;